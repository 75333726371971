.schedule-page .day-heading {
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.schedule-page .day-heading button {
 background-color: inherit;
 color: inherit;
 font-size: 0.5rem;
 font-weight: inherit;
 font-family: inherit;
 border: none;
 cursor: pointer;
}

.regular-vision .schedule-page .day-heading button {
 font-size: 0.8rem;
}
